import {
  OwnUpBodyItalic,
  ownUpDarkTheme,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpLargeBody,
  OwnUpSmallHeadlineBook,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import { ImageWrapper, LargeBody, LocationWrapper, NameWrapper, Section } from './elements';

export const ReviewSingle = ({
  ariaLabel,
  image,
  largeBody,
  names,
  location
}: {
  ariaLabel: string;
  image: JSX.Element | null;
  largeBody: string;
  names: string;
  location: string;
}) => (
  <OwnUpThemeProvider theme={ownUpDarkTheme}>
    <Section aria-label={ariaLabel}>
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridItem md={4} xs={12}>
            <OwnUpGridContainer variant="legacy">
              <OwnUpGridOffset xs />
              <OwnUpGridItem xl={8} md={10} sm={4} xs={6}>
                <ImageWrapper>{image}</ImageWrapper>
              </OwnUpGridItem>
              <OwnUpGridOffset xs />
            </OwnUpGridContainer>
          </OwnUpGridItem>
          <OwnUpGridItem md={8} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
            <OwnUpGridContainer variant="legacy">
              <OwnUpGridItem xl={10} md={12}>
                <LargeBody>
                  <OwnUpSmallHeadlineBook variant="h2">{largeBody}</OwnUpSmallHeadlineBook>
                </LargeBody>
                <NameWrapper>
                  <OwnUpLargeBody variant="body1">{names}</OwnUpLargeBody>
                </NameWrapper>
                <LocationWrapper>
                  <OwnUpBodyItalic variant="body1">{location}</OwnUpBodyItalic>
                </LocationWrapper>
              </OwnUpGridItem>
            </OwnUpGridContainer>
          </OwnUpGridItem>
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </Section>
  </OwnUpThemeProvider>
);
