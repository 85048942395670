import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpThemeProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { Section } from '../section';

const PaddedGridItem = styled(OwnUpGridItem)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: ${({ theme }) => theme.spacing(3)}px !important;
  }
`;

export const Intro = () => (
  <OwnUpThemeProvider theme={ownUpWhiteTheme}>
    <Section aria-label="reviews intro">
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="slim">
          <OwnUpGridOffset xs={0} xl={1} />
          <OwnUpGridItem xs={12} md={10} lg={6} xl={5}>
            <OwnUpBody variant="body1">
              After years of renting, Emily and Evan decided it was time to put down roots. But when
              they wanted to make a last-minute offer on their dream home, they couldn’t get a hold
              of anyone at their mortgage company. Their realtor recommended Own Up, and thanks to
              our innovative pre-qualification technology (a mortgage industry first), they had the
              pre-approval letter they needed within the hour.
            </OwnUpBody>
          </OwnUpGridItem>
          <PaddedGridItem xs={12} md={10} lg={6} xl={5}>
            <OwnUpBody variant="body1">
              “Everyone was so kind, so patient, so informative, and really just warm. They gave me
              so much confidence.” Own Up matched them to a lender at a preferred interest rate,
              saving them $38,000 over the life of their loan. “Own Up was a champion for us
              throughout the entire process … I can’t describe what saving that much money means to
              us!
            </OwnUpBody>
          </PaddedGridItem>
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </Section>
  </OwnUpThemeProvider>
);
