import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const ReviewSingleImageEmilyEvan = () => (
  <StaticImage
    src="../../images/emily-and-evan.png"
    alt="Happy Own Up customers Emily and Evan smile at the camera"
    placeholder="blurred"
    quality={100}
    layout="fullWidth"
    imgStyle={{
      borderRadius: '50%'
    }}
  />
);
