import { Theme } from '@material-ui/core';
import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpThemeProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import * as reviews from '../../data/content/reviews';
import { OverlineText, OverlineWrapper } from '../overline-text';
import { reviewImages } from '../static-images/shared';
import { ReviewIndividual, ReviewIndividualProps, Section } from './elements';

export const reviewers = ['evan', 'andrew', 'michael'] as const;
type Reviewer = (typeof reviewers)[number];

export type ReviewersData = {
  [reviewer in Reviewer]: ReviewIndividualProps;
};

const Review = ({ data, reviewer }: { data: ReviewersData; reviewer: Reviewer }) => {
  const { name, catchphrase, shortReview, image, review } = data[reviewer];

  return (
    <OwnUpGridItem xs={12} md={4}>
      <ReviewIndividual
        image={image}
        name={name}
        catchphrase={catchphrase}
        shortReview={shortReview}
        review={review}
      />
    </OwnUpGridItem>
  );
};

const Reviews = ({ data }: { data: ReviewersData }) => (
  <React.Fragment>
    {reviewers.map((reviewer, index) => (
      <Review key={index} data={data} reviewer={reviewer} />
    ))}
  </React.Fragment>
);

export const PureReviewsMultiple = ({
  data,
  theme = ownUpWhiteTheme
}: {
  data: ReviewersData;
  theme?: Theme;
}) => (
  <OwnUpThemeProvider theme={theme}>
    <Section aria-label="multiple reviews">
      <OverlineWrapper>
        <OverlineText>HEAR FROM OUR CUSTOMERS</OverlineText>
      </OverlineWrapper>
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <Reviews data={data} />
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </Section>
  </OwnUpThemeProvider>
);

export const ReviewsMultiple = ({ theme }: { theme?: Theme }) => {
  const reviewersData = reviewers.reduce((reduction, reviewer) => {
    const Image = reviewImages[`${reviewer}.png` as const];
    const { name, catchphrase, shortReview, review } = reviews[reviewer];

    reduction[reviewer] = {
      name,
      catchphrase,
      shortReview,
      review,
      image: Image
    };

    return reduction;
  }, {} as ReviewersData);

  return <PureReviewsMultiple data={reviewersData} theme={theme} />;
};
