import { ALOE_100, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Section = styled.section`
  background-color: ${ALOE_100}; /* fall back color */
  background-color: ${({ theme }: PropsWithTheme<{}>) =>
    theme.backgroundColors.primaryTheme?.backgroundColor};
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  }
`;

export const LargeBody = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

export const NameWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: flex;
    justify-content: center;
  }
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

export const LocationWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: flex;
    justify-content: center;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 300px;
`;
